<template>
  <div
      class="flex flex-col rounded-md mx-2 hover:a-background-light-primary h-full cursor-pointer pb-1 border border-transparent hover:border-a-neutral-dim hover:shadow-md"
  >
    <!-- Business Cover Image -->
    <NuxtImg
        v-if="business.logo_card || business?.cover"
        :src="(business?.cover ? business?.cover : business.logo_card) as string"
        class="object-cover h-40 rounded-t-md"
        alt=""
    />
    <div
        v-else
        class="flex flex-row items-center justify-center h-32"
    >
      <Icon
          name="mdi:briefcase-account"
          class="text-6xl text-a-neutral-lightest"
      />
    </div>
    <!-- Business Cover Image Ends -->
    <!-- Business Logo, Title And Slogan -->
    <div class="flex flex-1 flex-col w-full px-2">
      <div class="flex flex-row items-center justify-start">
        <NuxtImg
            v-if="business.logo_thumb"
            :src="business.logo_thumb"
            class="object-cover rounded-full h-12 w-12 border"
            alt=""
        />
        <div class="flex flex-col items-start justify-center px-2">
          <p class="text-base font-semibold my-1">{{ excerptedName }}</p>
          <p class="text-sm font-semibold italic text-a-secondary">
            {{ business.slogan }}
          </p>
        </div>
      </div>
      <!-- Business Logo, Title And Slogan Ends -->
      <!-- Business City State -->
      <div class="flex flex-row items-center font-semibold text-a-neutral-lightest justify-start p-1">
        <Icon
            name="mdi:map-marker"
            class="text-xl text-a-error-dark"
        />
        <div class="flex flex-row items-center text-xs text-a-neutral-light">
          &nbsp;
          <p>{{ business.city }},</p>
          &nbsp;
          <p>{{ business.state }}</p>
        </div>
      </div>
      <!-- Business City State Ends -->
      <!-- Business Coupons and Sales Count -->
      <div class="flex flex-row items-start justify-start p-1">
        <div
            class="flex flex-row items-center justify-start text-xs w-1/2"
            v-if="business?.active_coupons_count > 0"
        >
          <Icon
              name="mdi:tag-text"
              class="text-xl text-a-neutral-light"
          />
          <p>&nbsp;Coupons({{ business?.active_coupons_count }})</p>
        </div>
        <div
            class="flex flex-row items-center justify-start text-xs w-1/2"
            v-if="business.active_sales_count"
        >
          <Icon
              name="mdi:brightness-percent"
              class="text-xl text-a-neutral-light"
          />
          <p>&nbsp;Sales({{ business.active_sales_count }})</p>
        </div>
      </div>
      <!-- Business Coupons and Sales Count Ends -->
      <!-- Business Reviews Count  -->
      <div class="flex flex-row flex-wrap items-end justify-start p-1">
        <BaseRatingStar size-class="text-xl"/>
        <div
            class="flex flex-row text-sm"
            v-if="business.reviews_count > 0"
        >
          &nbsp;
          <p class="font-semibold text-a-secondary">{{ business.rating }}</p>
          &nbsp;
          <p class="text-xs my-0.5">({{ business.reviews_count }} reviews)</p>
        </div>
        <span
            v-else
            class="text-sm text-a-neutral-lightest italic"
        >Not Rated Yet</span
        >
      </div>
      <!-- Business Reviews Count Ends -->
      <!-- Business Categories -->
      <div class="flex flex-row flex-wrap my-2">
        <span
            v-for="category in business.business_categories"
            :key="category"
            class="bg-a-secondary-lightest text-a-secondary border-a-secondary border-2 rounded text-xs px-1 mx-0.5 my-1 py-0.5"
        >
          {{ category.title }}
        </span>
      </div>
      <!-- Business Categories Ends -->
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>
import type {HomeBusiness} from "~/types/main";

const props = withDefaults(defineProps<{
  business: HomeBusiness
}>(), {});

const excerptedName = computed(()=>{
  if(props.business.name.length <= 70){
    return props.business.name
  }
  return (props.business.name as string).slice(0,70)+'...';
})
</script>

<style></style>
